import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

// Keyboard Shortcuts
if (typeof document !== 'undefined') {
  // document.addEventListener('keydown', function(e) {console.log(e.key, e.keyCode)});
  document.addEventListener('keydown', (e) => {
    if (e.keyCode === 27) {
     // console.log("You have pressed ESC!");
     navigate("/admin/");
   }
  });
};

const Navigation = ({ siteTitle }) => (
  <nav>
    <Link to="/" aria-label="Home">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1123.19 1374.9" className="logo">
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path d="M1007.83,1289.22H318.6c-37.85,0-68.63-29-68.63-64.58V162c0-35.23,30.46-63.89,67.9-63.89H927.6a15.66,15.66,0,0,1,0,31.32H317.87c-20.17,0-36.58,14.61-36.58,32.57V1224.64c0,18.34,16.75,33.26,37.31,33.26h689.23a15.66,15.66,0,1,1,0,31.32Z"/>
            <path d="M1091.1,311.2H954.61c-34.28,0-62.17-26.29-62.17-58.61v-122c0-12.89,7.58-24.22,19.79-29.56a34.79,34.79,0,0,1,37.63,6.15l163.47,151a29.91,29.91,0,0,1,7.8,33C1116.38,303.36,1104.59,311.2,1091.1,311.2ZM926.48,129.39a4.06,4.06,0,0,0-.76.07,3.21,3.21,0,0,0-2,1V252.59c0,15,13.84,27.29,30.85,27.29h136.06L928.61,130.2A3,3,0,0,0,926.48,129.39Z"/>
            <path d="M1047.05,1289.22h-39.22a15.66,15.66,0,0,1,0-31.32h39.22c15.76,0,28.71-11.3,28.86-25.19l1.35-82.06a15.65,15.65,0,0,1,15.66-15.4h.26a15.67,15.67,0,0,1,15.4,15.92l-1.35,82C1106.88,1264,1079.87,1289.22,1047.05,1289.22Z"/>
            <path d="M855.84,1374.9h-690c-39.73,0-72-32.18-72-71.74V71.75C93.76,32.18,126.08,0,165.81,0L855.89,1.74A15.65,15.65,0,0,1,871.5,17.4v92.86a15.66,15.66,0,1,1-31.32,0V33l-674.42-1.7a40.62,40.62,0,0,0-40.68,40.43V1303.16a40.63,40.63,0,0,0,40.73,40.42H840.18v-70a15.66,15.66,0,1,1,31.32,0v85.68A15.65,15.65,0,0,1,855.84,1374.9Z"/>
            <path d="M108.35,476.61a15.57,15.57,0,0,1-10.86-4.38L0,378.38V130.8L116,20A15.67,15.67,0,0,1,137.62,42.6L31.32,144.19V365.06l87.89,84.62a15.65,15.65,0,0,1-10.86,26.93Z"/>
            <path d="M698.84,516.69h-55Q636.56,456,591.42,456q-29.05,0-43.85,15.3T532.78,517q0,22.66,16.35,42.51l117.28,144q32.43,39.69,32.43,85.31v30.61q0,49-32.43,84.89T588.3,940.13H532.78q-55,0-82.78-30.33t-27.76-90.41h55.53q-.52,6.24-.52,9.92,0,24.66,17.39,37.41a63.07,63.07,0,0,0,38.14,12.75q29.58,0,42.55-15.58t13-45.35q0-22.95-16.34-42.51L454.68,632.61q-32.44-39.39-32.44-85.31V516.69q0-49.31,32.44-85T532.78,396H588.3Q692.1,396,698.84,516.69Z"/>
            <path d="M738.15,940.13V516.69q0-49.59,32.57-85.17t78-35.57h111q45.4,0,78,35.72t32.57,85V940.13H959.74v-242h-111v242ZM848.69,638h111V516.69q0-24.65-16.47-42.37t-39-17.71q-22.83,0-39.17,17.71t-16.36,42.37Z"/>
            <path d="M463.52,982.88h-7.74c-.69-5.69-3.14-8.54-7.38-8.54q-4.09,0-6.16,2.16c-1.4,1.43-2.09,3.57-2.09,6.42a9.27,9.27,0,0,0,2.3,6L459,1009.16a18.65,18.65,0,0,1,4.56,12v4.3A17.25,17.25,0,0,1,459,1037.4a14.31,14.31,0,0,1-11,5h-7.81q-7.74,0-11.64-4.27t-3.91-12.72h7.81c0,.59-.07,1-.07,1.39a6.15,6.15,0,0,0,2.44,5.27,8.93,8.93,0,0,0,5.37,1.79c2.77,0,4.78-.72,6-2.19s1.82-3.59,1.82-6.38a9.12,9.12,0,0,0-2.3-6l-16.5-20.18a18.5,18.5,0,0,1-4.56-12v-4.3a17.21,17.21,0,0,1,4.56-12,14.32,14.32,0,0,1,11-5H448Q462.57,965.89,463.52,982.88Z"/>
            <path d="M477.53,1042.45v-68H462v-8.53h46.73v8.53H493.15v68Z"/>
            <path d="M522.72,965.89h15.64a14.34,14.34,0,0,1,11,5,17.23,17.23,0,0,1,4.58,12v42.58a17.22,17.22,0,0,1-4.58,11.94,14.31,14.31,0,0,1-11,5H522.72a14.36,14.36,0,0,1-11-5,17.12,17.12,0,0,1-4.58-12V982.88a17.14,17.14,0,0,1,4.58-12A14.36,14.36,0,0,1,522.72,965.89Zm0,17v42.58a8.54,8.54,0,0,0,2.29,5.94,7.35,7.35,0,0,0,11.06,0,8.54,8.54,0,0,0,2.29-5.94V982.88a8.44,8.44,0,0,0-2.33-6,7.31,7.31,0,0,0-11,0A8.54,8.54,0,0,0,522.72,982.88Z"/>
            <path d="M560.11,1042.45V965.89h31.18a14.34,14.34,0,0,1,11,5,17.23,17.23,0,0,1,4.58,12v4.62a19.35,19.35,0,0,1-3.58,11q-3.59,5.29-12,5.29H599a7.24,7.24,0,0,1,5.51,2.51,8.61,8.61,0,0,1,2.3,6v30.15H591.29v-25.52a8.55,8.55,0,0,0-2.33-6,7.21,7.21,0,0,0-5.49-2.52h-7.82v34.06Zm15.54-42.51h7.82a7.18,7.18,0,0,0,5.49-2.53,8.59,8.59,0,0,0,2.33-6v-8.53a8.44,8.44,0,0,0-2.33-6,7.25,7.25,0,0,0-5.49-2.5h-7.82Z"/>
            <path d="M628.58,1042.45v-34.06q-15.54-1.95-15.54-17V965.89h15.54v25.52a8.69,8.69,0,0,0,2.3,6,7.25,7.25,0,0,0,11,0,8.59,8.59,0,0,0,2.33-6V965.89h15.55v25.52q0,15-15.55,17v34.06Z"/>
            <path d="M666,1042.45v-17h15.54v17Z"/>
            <path d="M687.81,1042.45V982.88a17.13,17.13,0,0,1,4.57-12,14.38,14.38,0,0,1,11-5H719a14.34,14.34,0,0,1,11,5,17.19,17.19,0,0,1,4.59,12v59.57H719v-34.06H703.35v34.06Zm15.54-42.51H719V982.88a8.43,8.43,0,0,0-2.32-6,7.31,7.31,0,0,0-11,0,8.5,8.5,0,0,0-2.3,6Z"/>
            <path d="M740.74,1042.45V965.89h31.17a14.34,14.34,0,0,1,11,5,17.19,17.19,0,0,1,4.59,12v4.62a19.42,19.42,0,0,1-3.58,11q-3.58,5.29-12,5.29h7.74a7.26,7.26,0,0,1,5.52,2.51,8.65,8.65,0,0,1,2.3,6v30.15H771.91v-25.52a8.54,8.54,0,0,0-2.32-6,7.23,7.23,0,0,0-5.5-2.52h-7.81v34.06Zm15.54-42.51h7.81a7.2,7.2,0,0,0,5.5-2.53,8.58,8.58,0,0,0,2.32-6v-8.53a8.43,8.43,0,0,0-2.32-6,7.27,7.27,0,0,0-5.5-2.5h-7.81Z"/>
            <path d="M840.4,1025.46q-1,17-15.56,17H809.21a14.38,14.38,0,0,1-11-5,17.11,17.11,0,0,1-4.57-12V982.88A17.34,17.34,0,0,1,798.18,971a14.16,14.16,0,0,1,11-5.06h15.63q14.67,0,15.56,17h-7.82q-.92-8.46-7.74-8.46H817a7.26,7.26,0,0,0-5.51,2.5,8.5,8.5,0,0,0-2.3,6v42.58a8.53,8.53,0,0,0,2.28,5.94,7.19,7.19,0,0,0,5.53,2.51h7.82c4.57,0,7.16-2.81,7.74-8.45Z"/>
            <path d="M846.6,1042.45V965.89h15.54v34.05h15.63V965.89h15.56v76.56H877.77v-34.06H862.14v34.06Z"/>
            <path d="M907.28,1042.45V965.89h15.58v76.56Z"/>
            <path d="M968.09,965.89h15.55v47.49a29.35,29.35,0,0,1-7.84,20.48q-7.83,8.6-18.77,8.59H936.91V965.89h15.55v68h7.81a7.17,7.17,0,0,0,5.53-2.51,8.49,8.49,0,0,0,2.29-5.94Z"/>
            <path d="M989.84,1042.45V965.89h38.92v8.53h-23.37v25.52H1021v8.45h-15.63v25.52h23.37v8.54Z"/>
            <path d="M1074,982.88h-7.74q-1-8.53-7.37-8.54c-2.73,0-4.78.72-6.17,2.16s-2.09,3.57-2.09,6.42a9.32,9.32,0,0,0,2.3,6l16.51,20.26a18.6,18.6,0,0,1,4.56,12v4.3a17.2,17.2,0,0,1-4.56,11.94,14.29,14.29,0,0,1-11,5h-7.82q-7.72,0-11.64-4.27c-2.61-2.84-3.9-7.08-3.9-12.72h7.8c0,.59-.06,1-.06,1.39a6.12,6.12,0,0,0,2.44,5.27,8.9,8.9,0,0,0,5.36,1.79c2.78,0,4.78-.72,6-2.19s1.82-3.59,1.82-6.38a9.17,9.17,0,0,0-2.3-6l-16.5-20.18a18.45,18.45,0,0,1-4.56-12v-4.3a17.16,17.16,0,0,1,4.56-12,14.3,14.3,0,0,1,11-5h7.82Q1073,965.89,1074,982.88Z"/>
          </g>
        </g>
      </svg>
    </Link>
    <div className="nav-links">
      <a href="https://www.instagram.com/storyarchives/" target="_blank" rel="noopener noreferrer" className="social-link icon-link" aria-label="Instagram"
        onClick={e => {
          // e.preventDefault()
          trackCustomEvent({
            category: "Social Media",
            action: "Social Account Link Click",
            label: "Instagram",
          })
        }}      
      >
        <svg id="instagram-svg-link" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
          <g transform="translate(-1714 -58)">
            <circle cx="22" cy="22" r="22" transform="translate(1714 58)" fill="#ebe1ce" opacity="0"/>
            <path d="M14.589,38.967a7.516,7.516,0,1,0,7.516,7.516A7.5,7.5,0,0,0,14.589,38.967Zm0,12.4a4.886,4.886,0,1,1,4.886-4.886,4.9,4.9,0,0,1-4.886,4.886Zm9.577-12.71a1.753,1.753,0,1,1-1.753-1.753A1.749,1.749,0,0,1,24.166,38.659Zm4.978,1.779A8.676,8.676,0,0,0,26.776,34.3a8.733,8.733,0,0,0-6.142-2.368c-2.42-.137-9.675-.137-12.1,0A8.72,8.72,0,0,0,2.4,34.29,8.7,8.7,0,0,0,.028,40.432c-.137,2.42-.137,9.675,0,12.1A8.676,8.676,0,0,0,2.4,58.67a8.744,8.744,0,0,0,6.142,2.368c2.42.137,9.675.137,12.1,0a8.676,8.676,0,0,0,6.142-2.368,8.733,8.733,0,0,0,2.368-6.142C29.282,50.107,29.282,42.859,29.144,40.438ZM26.017,55.124a4.947,4.947,0,0,1-2.787,2.787c-1.93.765-6.509.589-8.641.589s-6.718.17-8.641-.589a4.947,4.947,0,0,1-2.787-2.787c-.765-1.93-.589-6.509-.589-8.641s-.17-6.718.589-8.641a4.947,4.947,0,0,1,2.787-2.787c1.93-.765,6.509-.589,8.641-.589s6.718-.17,8.641.589a4.947,4.947,0,0,1,2.787,2.787c.765,1.93.589,6.509.589,8.641S26.783,53.2,26.017,55.124Z" transform="translate(1721.814 34.568)" fill="#ebe1ce"/>
          </g>
        </svg>
      </a>
      {/* <a href="https://twitter.com/THEMEpodcast/" target="_blank" rel="noopener noreferrer" className="social-link icon-link" aria-label="Twitter"
        onClick={e => {
          // e.preventDefault()
          trackCustomEvent({
            category: "Social Media",
            action: "Social Account Link Click",
            label: "Twitter",
          })
        }}
      >
        <svg id="twitter-svg-link" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
          <g transform="translate(-1766 -58)">
            <circle cx="22" cy="22" r="22" transform="translate(1766 58)" fill="#ebe1ce" opacity="0"/>
            <path d="M30.05,54.861c.021.3.021.6.021.893,0,9.074-6.907,19.53-19.53,19.53A19.4,19.4,0,0,1,0,72.2a14.2,14.2,0,0,0,1.658.085,13.747,13.747,0,0,0,8.522-2.933,6.876,6.876,0,0,1-6.418-4.76,8.657,8.657,0,0,0,1.3.106,7.26,7.26,0,0,0,1.806-.234,6.865,6.865,0,0,1-5.5-6.737v-.085a6.913,6.913,0,0,0,3.1.871,6.874,6.874,0,0,1-2.125-9.181,19.511,19.511,0,0,0,14.153,7.183,7.749,7.749,0,0,1-.17-1.573,6.871,6.871,0,0,1,11.88-4.7,13.514,13.514,0,0,0,4.357-1.658,6.846,6.846,0,0,1-3.018,3.783,13.761,13.761,0,0,0,3.953-1.063A14.755,14.755,0,0,1,30.05,54.861Z" transform="translate(1771.045 19.358)" fill="#ebe1ce"/>
          </g>
        </svg>
      </a>
      <a href="https://www.facebook.com/themidnightexchange/" target="_blank" rel="noopener noreferrer" className="social-link icon-link" aria-label="Facebook"
        onClick={e => {
          // e.preventDefault()
          trackCustomEvent({
            category: "Social Media",
            action: "Social Account Link Click",
            label: "Facebook",
          })
        }}   
      >
        <svg id="facebook-svg-link" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
          <g transform="translate(-1818 -58)">
            <circle cx="22" cy="22" r="22" transform="translate(1818 58)" fill="#ebe1ce" opacity="0"/>
            <path d="M39.653,18.839l.93-6.061H34.767V8.845c0-1.658.812-3.275,3.417-3.275h2.644V.409A32.244,32.244,0,0,0,36.135,0c-4.79,0-7.92,2.9-7.92,8.159v4.62H22.89v6.061h5.324V33.492h6.553V18.839Z" transform="translate(1807.648 63.253)" fill="#ebe1ce"/>
          </g>
        </svg>
      </a>
      <a href="https://www.youtube.com/channel/UCSS_2w8OHbOqa6Tc3OSnRng/" target="_blank" rel="noopener noreferrer" className="social-link icon-link" aria-label="YouTube"
        onClick={e => {
          // e.preventDefault()
          trackCustomEvent({
            category: "Social Media",
            action: "Social Account Link Click",
            label: "YouTube",
          })
        }}  
      >
        <svg id="youtube-svg-link" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
          <g transform="translate(-1818 -58)">
            <circle cx="22" cy="22" r="22" transform="translate(1818 58)" fill="#ebe1ce" opacity="0"/>
            <path d="M50.181,67.961A4.523,4.523,0,0,0,47,64.757C44.191,64,32.933,64,32.933,64s-11.257,0-14.065.757a4.524,4.524,0,0,0-3.183,3.2c-.752,2.826-.752,8.721-.752,8.721s0,5.9.752,8.721a4.456,4.456,0,0,0,3.183,3.152c2.808.757,14.065.757,14.065.757s11.257,0,14.065-.757A4.456,4.456,0,0,0,50.181,85.4c.752-2.826.752-8.721.752-8.721S50.933,70.786,50.181,67.961ZM29.251,82.035V71.329l9.409,5.353Z" transform="translate(1807.067 3)" fill="#ebe1ce"/>
          </g>
        </svg>
      </a> */}
    </div>
  </nav>
)

Navigation.propTypes = {
  siteTitle: PropTypes.string,
}

Navigation.defaultProps = {
  siteTitle: ``,
}

export default Navigation
